import { UniDealerApiC } from '@unikey/unikey-commons/release/comm'
import { UniIdentityApiID } from '@unikey/unikey-commons/release/identity'

export const partnerKey: string = 'dmp';
export const environment: string = 'staging';

const commNonceEndpoint = { nonceEndpoint: 'https://dmp-stg-dealer-api.unikey.com/v5/nonces' };
export const api: UniDealerApiC = new UniDealerApiC('https://dmp-stg-dealer-api.unikey.com', 'v5', 'dmp'.toUpperCase(), { enableLogging: environment === 'development', preventRequestSubscriptions: '' as string === 'true', ...commNonceEndpoint })
export const identityApi: UniIdentityApiID = new UniIdentityApiID('https://identity-stg.unikey.com', 'api/v1', 'e57ec619-eac0-41d2-8061-1f9c5a57d2a2', 'i0V/78ToF3ve4jZAZ6s+6hFIEIzwLJ8EdsItuIcKWIMWwysXO4wioQWBmDM46AjqbFC2Z3Vje/KMf3KvPufr+w==', { enableLogging: environment === 'development' && false, preventRequestSubscriptions: '' as string === 'true', ...commNonceEndpoint });

export const captchaSiteKey: string = '6Lf1oUUaAAAAACSpw7TF9GqkkWHdd15Ul7Zdi0-o';
export const captchaSecretKey: string = '6Lf1oUUaAAAAADEmmXMMeG-zjjBZA17YxmmXQdpe';

export const termsOfServiceDocument: string = '/legal/termsofuse';
export const privacyPolicyDocument: string = '/legal/privacypolicy';
export const partnerSupportLink: string = 'https://dmp.com/Contact_Us';
export const deeplinkDomain = 'https://dmp-stg-dealer-web.unikey.com'

export const appInsightsInstrumentationKey: string = '24adfe98-c355-41b5-8d2d-622c0175fbc4';
export const mockAuth: string = '';

export const iosAppStoreLink: string = 'https://todo.unikey.com';
export const googlePlayStoreLink: string = 'https://todo.unikey.com';

export const oidcScope: string = 'openid email profile comm.dealer.api identity.api offline_access';
export const oidcClientId: string = 'e57ec619-eac0-41d2-8061-1f9c5a57d2a2';
export const oidcAuthority: string = 'https://identity-stg.unikey.com/.well-known/openid-configuration';
export const oidcRedirectUri: string = 'https://dmp-stg-web.unikey.com/#/token';
export const oidcRegisterUri: string = '';
export const oidcClientSecret: string = 'i0V/78ToF3ve4jZAZ6s+6hFIEIzwLJ8EdsItuIcKWIMWwysXO4wioQWBmDM46AjqbFC2Z3Vje/KMf3KvPufr+w==';
export const oidcPartnerBrandId: string = 'c0f8a0c6-4394-484a-9826-d6bcb58d28a2';
export const oidcPartnerBrandCode: string = 'DMP';
export const oidcSilentRedirectUri: string = 'https://dmp-stg-web.unikey.com/#/token';
export const oidcForgotPasswordRedirect: string = '';

export const buildId: string = '33792';
export const releaseId: string = 'Release-279';
export const cdpVersion: string = '5.3.1';
export const commonsVersion: string = '7.5.1';
export const desiredLocales: string = 'en,es,ja,fr,pt';