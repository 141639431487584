import React, { Component } from 'react'
import { IContainerCustomization } from '../PartnerCustomizations'
import {
  UniKeyV1CredentialC,
  RawCredentialC,
  WiegandCredentialC,
  Corp1000CredentialC,
  TSBnFCredentialC,
  TSBwFCredentialC,
  ECredentialType,
  IPartnerCredImpls
} from '@unikey/unikey-commons/release/comm'

export default () => {

  const dealerSkinningOffered = false;

  const partnerCredImpls: IPartnerCredImpls = {
    PartnerRCred: RawCredentialC,
    PartnerWCred: WiegandCredentialC,
    PartnerU1Cred: UniKeyV1CredentialC,
    PartnerC1000Cred: Corp1000CredentialC,
    Partner37BwFCred: TSBwFCredentialC,
    Partner37BnFCred: TSBnFCredentialC,
  };

  const partnerCredImplAvailability: ECredentialType[] = [
    ECredentialType.wiegand44Bit,
    ECredentialType.corp1000,
    ECredentialType.tsbNfacility,
    ECredentialType.tsbWfacility,

  ];

  const CredentialCreate: IContainerCustomization = {
    props: {
      partnerCredImplAvailability,
      partnerCredImpls,
      partnerDefaultCredentialType: ECredentialType.wiegand44Bit
    }
  }

  const CredentialList: IContainerCustomization = {
    props: {
      partnerCredImpls,
      partnerDefaultCredentialType: ECredentialType.wiegand44Bit
    }
  }

  const CredentialDetails: IContainerCustomization = {
    props: {
      partnerCredImpls,
      partnerDefaultCredentialType: ECredentialType.wiegand44Bit
    }
  }

  const DeviceList: IContainerCustomization = {
    props: {
      partnerCredImpls
    }
  }
  
  const DealerRegisterForm: IContainerCustomization = {
    props: {
      dealerSkinningOffered
    }
  }
  
  const DealerDetails: IContainerCustomization = {
    props: {
      dealerSkinningOffered
    }
  }

  const Portal: IContainerCustomization = {
    props: {
      dealerSkinningOffered
    }
  }

  return {
    Portal,
    CredentialCreate,
    CredentialList,
    CredentialDetails,
    DealerRegisterForm,
    DealerCreate: DealerRegisterForm,
    DeviceList,
    DealerDetails
  }
}
